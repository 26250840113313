import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Grid from "../../components/Grid"

const BlockMobileApp = ({ className, data }) => {
	var imageAlignment = "justify-center"
	if (data.imageAlignment) {
		switch (data.imageAlignment) {
			case "top":
				imageAlignment = "justify-start"
				break
			case "bottom":
				imageAlignment = "justify-end"
				break
			default:
				imageAlignment = "justify-center"
				break
		}
	}

	return (
		<div
			className={`w-full py-spacer-xl ${className} ${data.striping ? "enableStriping" : ""}`}>
			<Grid className="items-center sm:gap-y-[40px] md:gap-y-[50px] lg:gap-y-0">
				<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-11 lg:col-start-1 lg:col-span-6">
					<div className={`w-full h-full flex flex-row ${imageAlignment}`}>
						<GatsbyImage
							alt={data.image.alternativeText}
							image={getImage(data.image.localFile)}
							className=""
						/>
					</div>
				</div>
				<div
					className="
						col-start-1 col-end-13 md:col-start-2 md:col-end-11

						lg:col-end-12 lg:col-span-5
						xl:col-end-12 xl:col-span-4

					">
					<h2 className="display2 font-headline font-lg text-jetblack">{data.title}</h2>
					<p className="mt-20 text-jetblack">{data.description}</p>
					<p className="mt-20 text-jetblack">
						Want a clear overview of all our apps for smartphones and tablets? Download
						our{" "}
						<a
							href="/Hanseaticsoft-Cloud-Fleet-Manager-Mobile-Apps-Fact-Sheet.pdf"
							download
							className="underline text-tealblue hover:text-pictonblue">
							Cloud Fleet Manager mobile apps fact sheet
						</a>
						.
					</p>
					{(data.appleAppStoreAppUrl ||
						data.googlePlayStoreAppUrl ||
						data.windowsAppsStoreUrl) && (
						<div className="mt-spacer-sm flex flex-row flex-wrap gap-x-[25px] gap-y-[25px]">
							{data.appleAppStoreAppUrl && (
								<a href={data.appleAppStoreAppUrl} target="_blank" rel="noreferrer">
									<StaticImage
										src="../../images/appstore.png"
										width={170}
										alt={`Download ${data.title} on the Apple App Store`}
										title={`Download ${data.title} on the Apple App Store`}
										className=""
									/>
								</a>
							)}
							{data.appleAppStoreAppUrl && (
								<a
									href={data.googlePlayStoreAppUrl}
									target="_blank"
									rel="noreferrer">
									<StaticImage
										src="../../images/playstore.png"
										width={170}
										alt={`Download ${data.title} on the Google Play Store`}
										title={`Download ${data.title} on the Google Play Store`}
										className=""
									/>
								</a>
							)}
							{data.windowsAppsStoreUrl && (
								<a href={data.windowsAppsStoreUrl} target="_blank" rel="noreferrer">
									<StaticImage
										src="../../images/windowsAppsStore.png"
										width={170}
										alt={`Download ${data.title} on the Microsoft Windows Store`}
										title={`Download ${data.title} on the Microsoft Windows Store`}
										className=""
									/>
								</a>
							)}
						</div>
					)}

					<p></p>
				</div>
			</Grid>
		</div>
	)
}

export default BlockMobileApp
